import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { document, window } from 'browser-monads';

import UnderlinedHeading from '../components/underlined-heading';

const JourneyPage = () => {

  const navigateToSection = section => document.getElementById(section).scrollIntoView();
  // const navigateToSection = section => alert(section);

  let coords = [
    "192,157,518,526",
    "989,968,384,547",
    "998,442,1333,980",
    "1343,1155,1860,545",
    "1890,730,2496,1106",
    "2175,136,2607,626"
  ];

  const sections = [
    'launch',
    'early-operation',
    'cruise',
    'mars-orbit-insertion',
    'transition-to-science',
    'science'
  ]

  const resizeCoords = coords => {
    const img = document.getElementById('main-image');
    if (img) {
      const currentWidth = img.getBoundingClientRect().width;
      const ratio = currentWidth / 2616;
      coords = coords.split(",").map(e => Number(e.trim()) * ratio).join(",");
      return coords;
    }
    return "0,0,0,0";
  }

  const [areas, setAreas] = useState([]);

  useEffect(() => {
    document.getElementById('main-image').onload = () => {
      coords = coords.map(coord => resizeCoords(coord));
      setAreas(coords.map((coord, index) => <area target="_blank" alt="" title="" href="" shape="rect" coords={coords[index]} onClick={(e) => { e.preventDefault(); navigateToSection(sections[index])}} />));
    }
  }, []);

  return (
  <Layout>
    <SEO title="Home" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
          <canvas width="100%" height="100%"></canvas>
          <img src={require('../assets/images/journey-background.jpg')} alt="" data-uk-cover />
          <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section style={{marginTop: '-250px', position: 'relative'}}>
        <div className="standard-container journey-page-container">
          <div>
            <img src={require('../assets/images/journey-1.jpg')} id="main-image" useMap="#image-map" />
            <map name="image-map">{areas}</map>
          </div>

          <div className="lighter-background" id="launch">
            <UnderlinedHeading text="Launch" />
            <p>
            EMM began its journey to Mars from the Tanegashima island in Japan on July 20, 2020 at 1:58 AM (UAE time). The mission had a 30-day launch window between July 14 and August 12, 2020. On the launch day, the Hope Probe lifted off on a Mitsubishi H-IIA rocket toward the east, on a trajectory that took it over the Pacific Ocean.
            </p>
            <p>
            During the first stage, the solid rocket boosters were expended, as the rocket accelerated away from the Earth. This was followed by jettisoning the fairing as it no longer needed to protect the Hope Probe from the Earth’s atmosphere.
            </p>
            <p>
            In the second stage, the rocket jettisoned and put the Hope Probe into the Earth’s orbit. It stayed in orbit until the exact alignment with Mars after which it was reignited to push it on its trajectory towards the Red Planet. Following this, with the right direction and velocity of 11km/s, the upper stage gently deployed the Hope Probe.
            </p>
            <img src={require('../assets/images/journey-2.jpg')} />
          </div>

          <div className="lighter-background" id="early-operation">
            <UnderlinedHeading text="Early operation" />
            <p>
              After the Hope Probe separated from the upper stage, an automated sequence awakened the probe. This was followed by the booting up of the central computer and switching on of the heaters to prevent the fuel from freezing. The Hope Probe then deployed the solar array panels and used its sensors to find the sun and manoeuvre in such a way that the solar arrays began to charge the onboard battery.
            </p>
            <p>
            With the power switched on, the Hope Probe sent its first transmission to Earth, picked up by the NASA Deep Space Network ground station in Madrid. This was followed by the commissioning of the subsystems and components on the Hope Probe by the EMM spacecraft and operation teams. 
            </p>
            <p>
            The trajectory to Mars is further refined by the team over the course of its journey by performing a series of Trajectory Correction Manoeuvres (TCM). The first two TCMs were performed during this phase. 
            </p>
            <p>
            The Hope Probe will cover a total of 493,500,000 km on its way to Mars.
            </p>
            <img src={require('../assets/images/journey-3.jpg')} />
          </div>

          <div className="lighter-background" id="cruise">
            <UnderlinedHeading text="Cruise" />
            <p>
            The Hope Probe enters normal operation during this phase. As per requirement, further Trajectory Correction Manoeuvres (TCM) are done to keep the Hope Probe on track towards its destination. 
            </p>
            <p>
            The science instruments are commissioned during this phase. In the Hope Probe’s 7-month long journey, the instruments are checked at regular intervals to ensure that they are operating correctly. The instruments are calibrated using stars to ensure they are ready to operate once they arrive in Mars’ orbit.
            </p>
            <p>
              The Hope Probe approaches Mars with such a velocity that it will slingshot around it and continue into deep space if it isn’t slowed down to the level that it can be captured by Mars’ gravity.
            </p>
            <img src={require('../assets/images/journey-4.jpg')} />
          </div>

          <div className="lighter-background" id="mars-orbit-insertion">
            <UnderlinedHeading text="Mars orbit insertion" />
            <p>
              During the Mars Orbit Insertion phase, the Ground and Space Segments are kept to a minimum while the team focuses on safely entering a capture orbit at Mars. Nearly half of the fuel is spent to slow the Hope Probe down enough to capture Mars’ orbit. The fuel burn (firing the Delta V thrusters) will last approximately 30 mins and reduces the speed of the spacecraft from over 121,000 km/h to approximately 18,000 km/h. 
            </p>
            {/* <p>
            Being a critical operation, this phase is designed to be run autonomously. One of the reasons behind this is because the spacecraft will not be in the line of sight for the EMM team to operate it. 
            </p> */}
            <p>
            Since the Mars Orbit Insertion phase is as critical as the Launch phase, the spacecraft will need to be commissioned again and the instruments onboard tested before entering the transition to science phase. 
            </p>
            <img src={require('../assets/images/journey-5.jpg')} />
          </div>

          <div className="lighter-background" id="transition-to-science">
            <UnderlinedHeading text="Transition to Science" />
            <p>
            {/* Once the Mars Orbit Insertion is completed, the Hope Probe will ‘go dark’ – eclipsed by Mars. Only when the spacecraft emerges from the dark side of Mars will communication be re-established, and the team will only then be certain that the manoeuvre has succeeded. */}
            The first contact with the observatory after MOI will likely come from the ground station in Spain.
            </p>
            <p>
            The next stage in the Hope Probe’s journey is the transition from the Capture Orbit to an acceptable Science Orbit in preparation for its primary science operations. The Capture Orbit – an elliptical orbit lasting 40 hours, will take the Hope Probe as close as 1,000 km above Mars’ surface and as far as 49,380 km away from it.
            </p>
            <p>
            In the Capture Orbit, the first image of Mars from the science instruments will be taken and transmitted to the Mission Operation Centre (MOC). Daily contact is then scheduled with the team back on Earth, enabling a quick turn-around of command sequence uploads and telemetry receipt.
            </p>
            <p>
            After a period of testing and validation, a series of manoeuvres are performed to get the Hope Probe in the correct position to move into the Science Orbit.
            </p>
            <img src={require('../assets/images/journey-6.jpg')} />
          </div>

          <div className="lighter-background" id="science">
            <UnderlinedHeading text="Science" />
            <p>
            The Hope Probe will have an elliptical Science Orbit ranging between 20,000–43,000 km, with one complete orbit taking 55 hours. The mission’s highly innovative orbit is unique and will allow the Hope Probe to complete the first-ever planet-wide, 24x7 picture of Mars’ atmospheric dynamics and weather.
            </p>
            <p>
            The contact period with the Mission Operation Centre (MOC) is limited to 6-8 hours, twice a week. In the two years that the probe will be operational, it will capture novel data about Mars, its atmosphere and dynamics. The collected data will be made available to the scientific community through the Emirates Mars Mission’s data centre. 
            </p>
            <p>
            In the Science Orbit, the instruments will take routine observations of Mars’ surface and atmosphere, covering all geographic locations through the perspective of the latitude and longitude of the planet. 
            </p>
            <img src={require('../assets/images/journey-7.jpg')} />
          </div>
        </div>
      </section>
    </main>
  </Layout>
)
}

export default JourneyPage
