import React from 'react';

export default function UnderlinedHeading(props) {
    const { text, centerTrue } = props;
    return (
        <div style={{ display: centerTrue ? 'block' : 'inline-block' }}>
            <h2 className={`relative uk-text-center mt6 mb5`}>
                {text}
                <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
            </h2>
        </div>
    );
}